body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(138deg, rgba(0, 153, 105, 1) 0%, rgba(61, 0, 190, 1) 100%);
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
}

.button {
  padding: 10px;
  background-color: #fff;
  border: 0px;
  text-align: center;
  color: #333;
  font-size: 20px;
  cursor: pointer;
  float: right;
  margin-top: 20px;
  border-radius: 5px;
}

.reset {
  color: #fff;
  padding: 10px;
  background-color: transparent;
  border: 0;
  margin-top: 20px;
  font-size: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

input {
  padding: 10px 4px;
  width: 100%;
  box-sizing: border-box;
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 0;
  appearance: none;
  font-size: 16px;
  border-radius: 5px;
}

section {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 17px;
}

td {
  background-color: #fff;
  padding: 5px 10px;
  border-bottom: 1px solid #dedede;
}

thead {
  font-weight: bold;
}

h1 {
  font-weight: lighter;
  font-size: 50px;
  color: #fff;
  letter-spacing: 4px;
  margin-top: 0;
  margin-bottom: 20px;
}

h2 {
  font-weight: lighter;
  font-size: 25px;
  color: #fff;
  letter-spacing: 4px;
  margin-top: 0;
  margin-bottom: 10px;
}

table tbody tr:last-child td {
  border-bottom: 0;
}

.App {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  padding: 40px 0;
}
